// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import styled from '@emotion/styled'

import {
  Banner,
  VariableContent,
  Image,
  List,
  ListItem,
  LeshenPhoneCTA,
  PackageCard,
  Brandy,
  Price,
  SplitContent,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Columns, Column, Typography } from '@leshen/ui'
import { graphql, navigate } from 'gatsby'

import getFullStateName from '../utils/getFullStateName'

const SpanishStateTemplate = ({ data }) => {
  const { Meta_Description, State, Promo_Code, Page_Path, Meta_Title } =
    data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
    },
  }

  return (
    <Layout
      data={passedData}
      main={
        <>
          <SplitContent
            backgroundColor="dark"
            alignImageToBottom
            image={
              <Image
                data={data.heroImage.cloudinary[0].gatsbyImageData}
                alt={data.heroImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h1">
                  FRONTIER<sup>&reg;</sup> FIBER INTERNET
                </Typography>
                <Typography variant="h4">
                  DISPONIBLE EN {State_Name?.toUpperCase()}
                </Typography>
                <Price
                  standalone
                  className="price embedded-entry"
                  {...data.heroPrice?.price}
                  bullets={data?.heroPrice?.bullets}
                  variant="Default"
                />
                <List>
                  <ListItem>
                    <Typography>
                      Enrutador Amazon eero Wi-Fi Pro 6 incluido<sup>**</sup>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Sin límites de datos ni cargos por exceso
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Ancho de banda extremo para hogares inteligentes con
                      docenas de dispositivos
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <br />
                <LeshenPhoneCTA variant="hero" color="primary">
                  LLAMA AL
                </LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Frontier Fiber Internet: velocidades confiables para todas tus
                  necesidades
                </Typography>
                <Typography variant="">
                  Todos los planes de Internet de fibra óptica de Frontier
                  incluyen un router Wi-Fi premium GRATIS, SIN límites de datos
                  y velocidades de Internet que pueden conectar todos tus
                  dispositivos.
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned={false}
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <PackageCard
                label={false}
                packageData={data.package1}
                content={
                  <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
                }
              />
              <PackageCard
                label={false}
                packageData={data.package2}
                content={
                  <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
                }
              />
              <PackageCard
                label={false}
                packageData={data.package3}
                content={
                  <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
                }
              />
              <PackageCard
                label={false}
                packageData={data.package4}
                content={
                  <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
                }
              />
            </Columns>
          </VariableContent>
          <Banner
            backgroundColor="primary"
            layout="100"
            centerAligned="true"
            mainContent={
              <>
                <Typography variant="h4">
                  Consulta la disponibilidad por ciudad
                </Typography>
                <Typography variant="h5">
                  Encuentra las mejores ofertas de Internet en tu zona: Por
                  favor elige tu ciudad de la lista
                </Typography>
                <Dropdown
                  options={cityDropdownData}
                  onChange={(e) => navigate(e.value)}
                  placeholderText="Seleccionar Ciudad"
                />
                {cityDropdownData?.map((current) => (
                  <StyledLink href={current.value} key={current.value}>
                    {current.label}
                  </StyledLink>
                ))}
              </>
            }
          />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Todo está cubierto con Frontier Fiber Internet
                </Typography>
              </>
            }
            alignMainContent="Center"
            alignImageToBottom={false}
            centeredContent={false}
            backgroundColor={null}
            customLink={null}
          >
            <Columns
              className=""
              leftAligned
              cardStyled={false}
              mobileCarousel={false}
              extraTopSpacingForLabel={false}
            >
              <Column backgroundColor="" svg={false}>
                <Typography variant="h4">Tecnología y Velocidad</Typography>
                <Typography variant="">
                  No hay nada mejor que una conexión rápida prácticamente sin
                  demoras. Con Frontier Fiber Internet, obtienes velocidades 100
                  % de fibra óptica al alcance de tu mano. Impulsa todos los
                  dispositivos de tu hogar con la velocidad de la fibra y
                  disfruta del Internet como debe ser.
                </Typography>
              </Column>
              <Column backgroundColor="" svg={false}>
                <Typography variant="h4">Entretenimiento</Typography>
                <Typography variant="">
                  Una forma segura de perder el entusiasmo en tu programa
                  favorito es tener una conexión lenta. Asegúrate de que tu
                  entretenimiento esté respaldado por tecnología 100 % de fibra
                  óptica. Puedes transmitir, jugar, navegar y ver tus programas
                  y películas favoritos en cualquier momento y ahorrarte los
                  dramas por pausas molestas.
                </Typography>
              </Column>
              <Column backgroundColor="" svg={false}>
                <Typography variant="h4">Deportes y Cultura</Typography>
                <Typography variant="">
                  ¿Escuchas un podcast que te encanta? ¿A qué equipo le vas?
                  Frontier Fiber Internet te brinda el ancho de banda que
                  necesitas para transmitir, escuchar, mirar y disfrutar de tus
                  actividades en línea favoritas sin interrupciones. Ya sea para
                  ver los últimos momentos destacados de tu deporte de elección
                  o transmitir tu canal favorito en vivo, Frontier te respalda.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.vrGuyImage.cloudinary[0].gatsbyImageData}
                alt={data.vrGuyImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.vrGuyMobile.cloudinary[0].gatsbyImageData}
                alt={data.vrGuyImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Fibra óptica para usuarios pros de Internet
                </Typography>
                <Typography variant="h4">
                  Navega en Internet como nunca lo has hecho con nuevas
                  velocidades máximas de hasta 5 Gigas.
                </Typography>
                <Typography variant="">
                  Transmite películas 4K, participa en juegos de realidad
                  virtual y trabaja desde casa en docenas de dispositivos con
                  algunas de las velocidades de Internet más rápidas del
                  mercado.
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      Router Wi-Fi premium incluido con todos los planes de
                      Internet de fibra
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Todos los planes de Internet de Fibra poseen velocidades
                      de carga y descarga simétricas
                    </Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            backgroundColor="dark"
            mainContent={
              <>
                <Typography variant="h2">
                  Aumenta tu señal Wi-Fi desde $10/mes<sup>♦</sup>
                </Typography>
                <Typography>
                  Elimina los puntos muertos agregando extensores Wi-Fi para
                  todo el hogar a tu plan.
                </Typography>
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
            alignMainContent="Left"
          />
          <SplitContent
            backgroundColor=""
            alignImageToBottom
            image={
              <Image
                data={data.fatherDaughter.cloudinary[0].gatsbyImageData}
                alt={data.fatherDaughter.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.fatherDaughterMobile.cloudinary[0].gatsbyImageData}
                alt={data.fatherDaughterMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Una mejor manera de ver TV en vivo
                </Typography>
                <Typography variant="">
                  Mira tus canales favoritos con Frontier Fiber y YouTube TV.
                  Obtén más de 100 canales de deportes en vivo, noticias de
                  última hora y programas y películas imperdibles. Regístrate
                  hoy y ahorra $15 al mes durante los próximos 12 meses.^ Sin
                  necesidad de instalación, puedes registrarte y comenzar a
                  mirar en minutos. Al agregar YouTube TV a tu paquete de
                  Fronter, recibirás:
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Más de 100 canales en vivo</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Grabación gratuita de videograbadora con almacenamiento
                      ilimitado en la nube.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Sin contratos a largo plazo</Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <SplitContent
            image={
              <Image
                data={data.homePhoneImage.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImage.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mobileImage={
              <Image
                data={data.homePhoneImageMobile.cloudinary[0].gatsbyImageData}
                alt={data.homePhoneImageMobile.cloudinary[0].context.custom.alt}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  Ahorra en grande con los paquetes de Frontier en {State_Name}
                </Typography>
                <Typography>
                  Obtén todos los servicios para el hogar que necesitas con un
                  plan combinado de Frontier. Cuando elijas el teléfono e
                  Internet de Frontier en {State_Name}, obtendrás ambos
                  servicios en una sola factura por un precio bajo. Estos son
                  algunos de los beneficios que te esperan con un paquete
                  combinado de Frontier:
                </Typography>
                <List>
                  <ListItem>
                    <Typography>Llamadas nacionales ilimitadas</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Datos mensuales ilimitados</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Soporte técnico gratuito 24/7</Typography>
                  </ListItem>
                </List>
                <br />
                <LeshenPhoneCTA color="primary">LLAMA AL</LeshenPhoneCTA>
              </>
            }
          />
          <VariableContent
            backgroundColor=""
            mainContent={
              <>
                <Brandy
                  symbol={data.disclaimerOne.symbol}
                  text={data.disclaimerOne.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerTwo.symbol}
                  text={data.disclaimerTwo.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerThree.symbol}
                  text={data.disclaimerThree.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFour.symbol}
                  text={data.disclaimerFour.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerFive.symbol}
                  text={data.disclaimerFive.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSix.symbol}
                  text={data.disclaimerSix.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerSeven.symbol}
                  text={data.disclaimerSeven.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerEight.symbol}
                  text={data.disclaimerEight.text}
                  variant="legal"
                />
                <Brandy
                  symbol={data.disclaimerNine.symbol}
                  text={data.disclaimerNine.text}
                  variant="legal"
                />
              </>
            }
            alignMainContent="Left"
          />
        </>
      }
    />
  )
}

SpanishStateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default SpanishStateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query SpanishStateTemplateQuery(
    $pagePath: String!
    $stateAbbreviation: String!
  ) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerFrontierbundlesAoaSpanish(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "cd610f2a-c91d-5823-9488-fbca32d2101c" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "0f20b283-aadf-507e-bc15-5aef12af97d7" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerFrontierbundlesAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    noContractIcon: contentfulMedia(
      contentful_id: { eq: "UOmJklixDyTIWZJr0SfSv" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    routerIcon: contentfulMedia(
      contentful_id: { eq: "1xbtm0bhAi89Njc98kXFdH" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    speedometerIcon: contentfulMedia(
      contentful_id: { eq: "7AvooAr2FSy6ebTFPqEpbK" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    homePhoneImage: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughter: contentfulMedia(
      contentful_id: { eq: "143WS7Pjyp06lj3FDYDYXT" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    fatherDaughterMobile: contentfulMedia(
      contentful_id: { eq: "2TCp3iv1NvPWYTnPMZdKiw" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuyImage: contentfulMedia(
      contentful_id: { eq: "2sMKV2U1U1HBIuCtju9yUn" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    vrGuyMobile: contentfulMedia(
      contentful_id: { eq: "5sK1DE06j0lUoOcnUzUBXA" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroImage: contentfulMedia(
      contentful_id: { eq: "3jjN46RLszRN4O1SgIagy9" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    homePhoneImageMobile: contentfulMedia(
      contentful_id: { eq: "4USDaC0rxHAy1lsc4iEkq0" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    heroPrice: brandyPackage(brandy_id: { eq: "ftr-sigil-fiber-hero-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package1: brandyPackage(brandy_id: { eq: "ftr-sigil-5gig-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package2: brandyPackage(brandy_id: { eq: "ftr-sigil-2gig-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package3: brandyPackage(brandy_id: { eq: "ftr-sigil-1gig-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    package4: brandyPackage(brandy_id: { eq: "ftr-sigil-500mbp-es" }) {
      brand_token
      brandy_id
      name
      details
      bullets {
        text
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      price {
        currency
        amount
        sale
        prefix
        duration
        suffix
        before
        disclaimer {
          contentful_id
          symbol
          text
        }
      }
      comparison
      labelOffer
    }
    disclaimerOne: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-rewardcard-fiber-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerTwo: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-eeroo-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerThree: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-archer-router-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerFour: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-digital-voice-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerFive: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-frt-frontiersecure-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerSix: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-fiber-mptp-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerSeven: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-wholehomewifi-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerEight: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-youtubetv-es" }
    ) {
      id
      text
      symbol
    }
    disclaimerNine: brandyDisclaimer(
      brandy_id: { eq: "disclaimer-ftr-es-fiberfooter" }
    ) {
      id
      text
      symbol
    }
  }
`
